<template>
  <FormWrapper
    :form="form"
    :hideForm="hideForm"
    :handleOpened="() => handleProps({ key: 'opened', value: !form.opened })"
    attachModule="ectoscopia"
  >
    <b-row>
      <b-col>
        <div class="form-group mb-0 break-spaces">
          <v-text-area
            id="ectoscopia"
            :value="form.fields.ectoscopia"
            @blur="updateMedicalRecord(form)"
            @inputTextArea="el => updateForm('ectoscopia', el)"
            :readonly="!canEdit"
            placeholder="Descrever"
            rows="1"
          />
        </div>
      </b-col>
    </b-row>
  </FormWrapper>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import FormWrapper from '@/components/Attendance/Forms/FormWrapper'
import TextArea from '@/components/General/TextArea'

export default {
  components: {
    FormWrapper,
    'v-text-area': TextArea,
  },
  computed: {
    ...mapState({
      canEdit: state => state.attendance.canEdit,
      form: state => state.attendance.form.ectoscopia,
    })
  },
  methods: {
    ...mapActions('attendance/form', ['updateMedicalRecord', 'destroyMedicalRecord']),
    ...mapActions('attendance/form/ectoscopia', ['handleFields', 'handleProps']),
    updateForm(key, value) {
      this.handleFields({ key, value })
    },
    hideForm(){
      this.handleProps({ key: 'showing', value: false })
      this.destroyMedicalRecord(this.form)
      this.form.fields.ectoscopia = null
    }
  }
}
</script>
